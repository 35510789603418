import React from 'react'
import {
  BriefcaseIcon,
  ClipboardListIcon,
  CurrencyEuroIcon,
  IdentificationIcon,
  LibraryIcon,
  ScaleIcon,
  ShieldCheckIcon,
  UserGroupIcon,
} from '@heroicons/react/outline'

import SectionContainer from '../components/section-container'
import Layout from '../components/layout'
import SEO from '../components/seo'

const ServicesPage = () => (
  <Layout>
    <SEO title="Juridiskie Pakalpojumi" />
    <SectionContainer branded>
      <p className="text-justify">
        Juridiskais birojs „DTE” ir apņēmības pilns sniegt kvalitatīvu juridisko
        palīdzību, kas vērsta uz klientu mērķu sasniegšanu. Nodrošinām efektīvu
        un profesionālu lietas juridisko risinājumu, izmantojot individuālu
        pieeju un iedziļinoties problēmas būtībā.
      </p>
    </SectionContainer>
    <SectionContainer>
      <ul className="flex flex-wrap">
        <li className="w-1/2 p-4">
          <IdentificationIcon className="h-5 w-5 text-blue-700 mx-auto" />
          <h1 className="text-blue-700 font-medium text-center mb-1">
            Darba tiesības
          </h1>
          <ul className="text-center">
            <li>
              konsultācijas darba tiesību jautājumos (darba strīdi, darba
              tiesisko attiecību pārtraukšana, darba samaksa u.c.);
            </li>
            <li>
              dokumentu (darba līgumi, rīkojumi u.c.) izvērtēšana un
              sagatavošana atbilstoši konkrētajai situācijai;
            </li>
            <li>klientu pārstāvība Valsts darba inspekcijā;</li>
            <li>darba strīdu risināšana pirmstiesas un tiesas procesos.</li>
          </ul>
        </li>
        <li className="w-1/2 p-4">
          <BriefcaseIcon className="h-5 w-5 text-blue-700 mx-auto" />
          <h1 className="text-blue-700 font-medium text-center mb-1">
            Saistību tiesības
          </h1>
          <ul className="text-center">
            <li>
              līgumu sagatavošana – pirkuma, nomas, īres, dāvinājuma, aizdevuma,
              patapinājuma u.c. līgumi;
            </li>
            <li>
              līgumu izvērtēšana, rediģēšana un konsultācijas par jau
              noslēgtajiem līgumiem;
            </li>
            <li>līgumu sastādīšana pēc klienta pieprasījuma;</li>
            <li>
              strīdu situāciju, kuras radušās no saistību tiesībām, risināšana
              un konsultāciju sniegšana.
            </li>
          </ul>
        </li>
        <li className="w-1/2 p-4">
          <CurrencyEuroIcon className="h-5 w-5 text-blue-700 mx-auto" />
          <h1 className="text-blue-700 font-medium text-center mb-1">
            Komerctiesības
          </h1>
          <ul className="text-center">
            <li>
              juridiskais atbalsts un dokumentu sagatavošana uzņēmuma
              dibināšanai;
            </li>
            <li>
              palīdzība uzņēmuma reorganizācijas procesos (apvienošana,
              sadalīšana, pārveidošana);
            </li>
            <li>dokumentu sagatavošana uzņēmuma likvidācijas procesā.</li>
          </ul>
        </li>
        <li className="w-1/2 p-4">
          <LibraryIcon className="h-5 w-5 text-blue-700 mx-auto" />
          <h1 className="text-blue-700 font-medium text-center mb-1">
            Nekustamā īpašuma tiesības
          </h1>
          <ul className="text-center">
            <li>
              pārstāvība un konsultācijas ar nekustamo īpašumu saistītos
              jautājumos;
            </li>
            <li>
              konsultācijas un dokumentu sagatavošana dzīvojamās mājas
              pārvaldīšanas un apsaimniekošanas jautājumos;
            </li>
            <li>
              konsultācijas un dokumentu sagatavošana iegādājoties vai pārdodot
              nekustamo īpašumu;
            </li>
            <li>pārstāvība un konsultācijas nomas un īres darījumos.</li>
          </ul>
        </li>
        <li className="w-1/2 p-4">
          <UserGroupIcon className="h-5 w-5 text-blue-700 mx-auto" />
          <h1 className="text-blue-700 font-medium text-center mb-1">
            Ģimenes tiesības
          </h1>
          <ul className="text-center">
            <li>
              konsultācijas laulības šķiršanas lietās un prasības pieteikumu
              sagatavošana;
            </li>
            <li>
              konsultācijas par laulāto mantas sadali un dokumentu sagatavošana;
            </li>
            <li>
              konsultācijas bērnu aizbildniecības un aizgādības jautājumos;
            </li>
            <li>
              konsultācijas un dokumentu sagatavošana uzturlīdzekļu lietās.
            </li>
          </ul>
        </li>
        <li className="w-1/2 p-4">
          <ShieldCheckIcon className="h-5 w-5 text-blue-700 mx-auto" />
          <h1 className="text-blue-700 font-medium text-center mb-1">
            Darba aizsardzība
          </h1>
          <ul className="text-center">
            <li>
              konsultācijas darba aizsardzības jautājumos, tai skaitā par
              nelaimes gadījumiem darbā;
            </li>
            <li>
              ievadapmācības un darba aizsardzības instruktāžu sagatavošana;
            </li>
            <li>darba aizsardzības dokumentu audits.</li>
          </ul>
        </li>
        <li className="w-1/2 p-4">
          <ClipboardListIcon className="h-5 w-5 text-blue-700 mx-auto" />
          <h1 className="text-blue-700 font-medium text-center mb-1">
            Administratīvais process
          </h1>
          <ul className="text-center">
            <li>
              pārstāvība administratīvajā procesā valsts un pašvaldības
              iestādēs;
            </li>
            <li>pārstāvība administratīvajā procesā tiesā;</li>
            <li>iesniegumu, sūdzību u.c. dokumentu sagatavošana;</li>
            <li>
              administratīvo aktu un faktiskās rīcības apstrīdēšana un
              pārsūdzēšana;
            </li>
            <li>administratīvo tiesu nolēmumu pārsūdzēšana.</li>
          </ul>
        </li>
        <li className="w-1/2 p-4">
          <ScaleIcon className="h-5 w-5 text-blue-700 mx-auto" />
          <h1 className="text-blue-700 font-medium text-center mb-1">
            Pārstāvība tiesā
          </h1>
          <ul className="text-center">
            <li>konsultācijas par tiesvedības procesu;</li>
            <li>dokumentu sagatavošana prasības celšanai;</li>
            <li>nepieciešamo dokumentu sagatavošana tiesvedības ietvaros;</li>
            <li>klientu pārstāvība tiesvedības procesā;</li>
            <li>apelācijas un kasācijas sūdzību sagatavošana.</li>
          </ul>
        </li>
      </ul>
    </SectionContainer>
  </Layout>
)

export default ServicesPage
